import { ContactForm } from "@components/ui/modals/contact-form/ContactForm";
import { ContactFormSubmitted } from "@components/ui/modals/contact-form/ContactFormSubmitted";
import type {
    ILinksCollection,
    IModuleSizeGuideModal,
    ISiteSettings,
} from "@contentful-api/types/contentful";
import { ModalTypes } from "@lib/enums/ModalTypes";
import dynamic from "next/dynamic";
import type { FC, ReactNode } from "react";
import { createContext, useCallback, useContext, useMemo, useState } from "react";
import ForgotPassword from "./auth/login/ForgotPassword";

const LogInModal = dynamic(() => import("@components/auth/login/LogInModal"));
const SignUpModal = dynamic(() => import("@components/auth/sign-up/SignUpModal"));

const LoggedOutModal = dynamic(
    () => import("@components/ui/modals/logged-out-modal/LoggedOutModal")
);
const ThankYouForSignUpModal = dynamic(
    () => import("@components/ui/modals/thank-you-modal/ThankYouForSignUpModal")
);
const SizeGuideModal = dynamic(() => import("@components/ui/size-guide-modal/SizeGuideModal"));
const ChatIsNotAvailable = dynamic(() => import("@components/ui/live-chat/ChatIsNotAvailable"));
const LiveChatForm = dynamic(() => import("./ui/live-chat/LiveChatForm"));
const Chat = dynamic(() => import("./ui/live-chat/Chat"));
const ShippingDetails = dynamic(
    () => import("./commerce/checkout/delivery-step/ShippingDetailsModal")
);
const BaseModal = dynamic(() => import("@components/ui/modals/base-modal/BaseModal"));

export interface IAuthorizationModules {
    offerToLogIn: ISiteSettings["fields"]["offerToLogIn"];
    offerToCreateAnAccount: ISiteSettings["fields"]["offerToCreateAnAccount"];
    policiesAgreementTerms: ISiteSettings["fields"]["policiesAgreementTerms"];
    authorizationInvitation: ISiteSettings["fields"]["authorizationInvitation"];
    forgotYourPassword: ISiteSettings["fields"]["forgotYourPassword"];
}

export interface IContactFormModules {
    contactFormAgreement: ISiteSettings["fields"]["contactFormAgreement"];
    reCaptchaAgreement: ISiteSettings["fields"]["reCaptchaAgreement"];
}

export interface IShippingDetailsModal {
    shippingDetailsModal: ISiteSettings["fields"]["shippingDetailsModal"];
}

export interface ILiveChatModal {
    liveChatAgreement: ISiteSettings["fields"]["liveChatAgreement"];
    liveChatOfflineDescription: ISiteSettings["fields"]["liveChatOfflineDescription"];
}

type ModalMap = {
    [key in ModalTypes]?: React.ComponentType<any>;
};

const modalMap: ModalMap = {
    [ModalTypes.login]: LogInModal,
    [ModalTypes.signOut]: LoggedOutModal,
    [ModalTypes.sizeGuide]: SizeGuideModal,
    [ModalTypes.createAccount]: SignUpModal,
    [ModalTypes.thankYouForSignUp]: ThankYouForSignUpModal,
    [ModalTypes.forgotPassword]: ForgotPassword,
    [ModalTypes.contactForm]: ContactForm,
    [ModalTypes.contactFormSubmitted]: ContactFormSubmitted,
    [ModalTypes.shippingDetails]: ShippingDetails,
    [ModalTypes.liveChat]: LiveChatForm,
    [ModalTypes.chatIsNotAvailable]: ChatIsNotAvailable,
    [ModalTypes.chat]: Chat,
    [ModalTypes.base]: BaseModal,
};

type GlobalModalContextType = {
    showModal: (modalType: string, modalProps?: any) => void;
    hideModal: () => void;
    modalType: ModalTypes;
    modalProps: IAuthorizationModules;
};

const initialState: GlobalModalContextType = {
    showModal: () => {},
    hideModal: () => {},
    modalType: null,
    modalProps: null,
};

const GlobalModalContext = createContext(initialState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

interface ModalProps extends IAuthorizationModules {
    linkCollection?: ILinksCollection;
    sizeGuideModal?: IModuleSizeGuideModal;
    isOpen?: boolean;
    onClose?: () => void;
}

interface GlobalModalProviderProps {
    authorizationModules: IAuthorizationModules;
    linkCollection: ILinksCollection;
    sizeGuideModal: IModuleSizeGuideModal;
    shippingDetailsModal: IShippingDetailsModal;
    contactFormModules: IContactFormModules;
    liveChatModal?: ILiveChatModal;
    children: ReactNode;
}

export const GlobalModalProvider: FC<GlobalModalProviderProps> = ({
    authorizationModules,
    linkCollection,
    sizeGuideModal,
    shippingDetailsModal,
    contactFormModules,
    liveChatModal,
    children,
}) => {
    const [modalType, setModalType] = useState<ModalTypes>(null);
    const [modalProps, setModalProps] = useState<IAuthorizationModules>(null);

    const showModal = useCallback((_modalType: ModalTypes, _modalProps: IAuthorizationModules) => {
        setModalType((prevModalType) => (prevModalType === _modalType ? null : _modalType));
        setModalProps(_modalProps);
    }, []);

    const hideModal = useCallback(() => {
        setModalType(null);
        setModalProps(null);
    }, []);

    const renderComponent = () => {
        const ModalComponent = modalMap[modalType];

        if (!ModalComponent) {
            return null;
        }

        let props: ModalProps = modalProps;

        if (modalType === ModalTypes.base) {
            props = modalProps;
        }

        if (modalType === ModalTypes.chat) {
            props = {
                ...modalProps,
                ...liveChatModal,
            };
        }

        if (
            modalType === ModalTypes.createAccount ||
            modalType === ModalTypes.login ||
            modalType === ModalTypes.forgotPassword
        )
            props = {
                ...modalProps,
                ...authorizationModules,
            };

        if (modalType === ModalTypes.contactForm || modalType === ModalTypes.contactFormSubmitted) {
            props = {
                ...modalProps,
                ...contactFormModules,
            };
        }

        if (modalType === ModalTypes.thankYouForSignUp) props = { linkCollection, ...modalProps };

        if (modalType === ModalTypes.sizeGuide)
            props = {
                isOpen: true,
                onClose: hideModal,
                sizeGuideModal,
                ...modalProps,
            };

        if (modalType === ModalTypes.shippingDetails) {
            props = {
                isOpen: true,
                onClose: hideModal,
                ...shippingDetailsModal,
                ...modalProps,
            };
        }

        if (modalType === ModalTypes.liveChat || modalType === ModalTypes.chatIsNotAvailable) {
            props = {
                ...modalProps,
                ...liveChatModal,
            };
        }

        return <ModalComponent {...props} />;
    };

    const providerValue = useMemo(
        () => ({ modalType, modalProps, showModal, hideModal }),

        [hideModal, modalProps, modalType, showModal]
    );

    return (
        <GlobalModalContext.Provider value={providerValue}>
            {renderComponent()}
            {children}
        </GlobalModalContext.Provider>
    );
};
