export const SEARCH_QUERY = "soft 7";
export const SEARCHBAR_DESKTOP = "searchbar-desktop";
export const SEARCHBAR_INPUT = "searchbar-input";
export const QUERY_SUGGESTIONS_PLUGIN = "QuerySuggestionsPlugin";
export const PRODUCT_HITS_GRID_SELECTOR = "[data-testid=ProductHitsGrid]";
export const PRODUCT_HITS_GRID = "ProductHitsGrid";
export const PRODUCT_TILE = "ProductTile";

export const DISABLED_RULES = {
    "document-title": { enabled: false },
    "meta-viewport": { enabled: false },
    region: { enabled: false },
    "svg-img-alt": { enabled: false },
    "heading-order": { enabled: false },
    "frame-title": { enabled: false },
};
