import { useGlobalModalContext } from "@components/GlobalModalProvider";
import { Portal } from "@ui/components/layout/portal/Portal";
import { defaultUnderlineStyle } from "@ui/css/common";
import { useDisclosure } from "@ui/hooks/useDisclosure";
import {
    animatedContainerMobileStyles,
    toggleNavigationButtonStyles,
} from "@components/layouts/navigation/styles";
import type { INavigation } from "@contentful-api/types/contentful";
import { useAuth } from "@lib/auth/useAuth";
import { CommonCMS } from "@lib/constants/contentful";
import ClickAndCollect from "@lib/constants/contentful/clickAndCollect";
import {
    createAccountPage,
    customerServicePages,
    getPagePathByLocale,
    myAccountPage,
    storeLocatorPage,
} from "@lib/constants/pagePaths";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { useFreshData } from "@store/hooks";
import { IconButton } from "@ui/components/forms/icon-button/IconButton";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { NextLink } from "@ui/components/navigation/link/NextLink";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { sizes, space } from "ui/src/design-tokens";
import { MobileBottomContainer } from "./MobileBottomContainer";
import { MobileNav } from "./MobileNav";
import { TOGGLE_NAVIGATION_BUTTON } from "@e2e/helpers/navigation/constants";
interface MobileNavigationContainerProps {
    navigation: INavigation;
    showSearch?: boolean;
    isProductPage?: boolean;
    searchBarOpen?: boolean;
    clientIsDesktop?: boolean;
}

export const MobileNavigationContainer = ({
    navigation,
    searchBarOpen,
    clientIsDesktop,
}: MobileNavigationContainerProps) => {
    const { isOpen, getButtonProps, onClose } = useDisclosure();
    const { getMultiple } = useMicrocopy();
    const { asPath, events, locale } = useRouter();
    const { isAuthenticated, session } = useAuth();
    const { showModal } = useGlobalModalContext();
    useFreshData({});
    const [hidden, setHidden] = useState(!isOpen);

    const microcopies = (() => {
        return getMultiple(CommonCMS.global, [
            CommonCMS.myAccount,
            CommonCMS.help,
            ClickAndCollect.stores,
        ]);
    })();

    useEffect(() => {
        if (searchBarOpen && isOpen) {
            onClose();
        }
    }, [isOpen, onClose, searchBarOpen]);

    useEffect(() => {
        events.on("routeChangeStart", onClose);
        return () => {
            events.off("routeChangeStart", onClose);
        };
    }, [asPath, onClose, events]);

    const {
        "aria-hidden": ariaHidden,
        "aria-expanded": ariaExpanded,
        ...buttonsProps
    } = getButtonProps();
    const MobileNavigationContainerProps = {
        isAuthenticated,
        session,
        showModal,
    };

    return (
        <>
            <Flex gap={3} alignItems={"center"}>
                <IconButton
                    variant={"tertiary"}
                    display={["flex", "flex", "flex", "flex", "none"]}
                    aria-label={"Toggle Navigation"}
                    h={sizes.navBarCollapsedHeight}
                    w={"10.4"}
                    aria-expanded={isOpen}
                    bgColor="white"
                    data-testid={TOGGLE_NAVIGATION_BUTTON}
                    sx={toggleNavigationButtonStyles(searchBarOpen)}
                    icon={
                        isOpen ? (
                            <ECCOIcon
                                color="black"
                                data-testid="modalstatebutton,closemodal"
                                aria-label="Close"
                                name="close"
                            />
                        ) : (
                            <ECCOIcon
                                color="black"
                                data-testid="modalstatebutton,openmodal"
                                aria-label="menu"
                                name="menu"
                            />
                        )
                    }
                    {...buttonsProps}
                />
            </Flex>
            {!clientIsDesktop && (
                <>
                    <motion.div
                        hidden={hidden}
                        initial={false}
                        onAnimationStart={() => setHidden(false)}
                        onAnimationComplete={() => setHidden(!isOpen)}
                        animate={{ height: isOpen ? "85vh" : 0 }}
                        transition={{ ease: [0.85, 0, 0.15, 1], duration: 0.5 }}
                        style={animatedContainerMobileStyles}
                    >
                        <MobileNav navigation={navigation} />
                        <Flex flexDirection={"column"} justifyContent={"flex-start"}>
                            <Flex
                                bg="white"
                                flexDirection={"column"}
                                gap={4}
                                px={4}
                                py={space["6"]}
                                fontWeight="semibold"
                            >
                                <NextLink
                                    href={
                                        isAuthenticated
                                            ? getPagePathByLocale(myAccountPage, locale)
                                            : getPagePathByLocale(createAccountPage, locale)
                                    }
                                    leftIcon={<ECCOIcon name="account" />}
                                    aria-label={microcopies[CommonCMS.myAccount]}
                                    textDecoration={"none"}
                                    sx={{
                                        _hover: {
                                            "--b": "100%",
                                            color: "black",
                                            ...defaultUnderlineStyle,
                                        },
                                        _focusr: {
                                            "--b": "100%",
                                            color: "black",
                                            ...defaultUnderlineStyle,
                                        },
                                        _active: {
                                            textDecoration: "none",
                                            color: "black",
                                        },
                                    }}
                                >
                                    {microcopies[CommonCMS.myAccount]}
                                </NextLink>
                                <NextLink
                                    href={getPagePathByLocale(storeLocatorPage, locale)}
                                    leftIcon={<ECCOIcon name="store-locator" />}
                                    aria-label={microcopies[ClickAndCollect.stores]}
                                    textDecoration="none"
                                    sx={{
                                        _hover: {
                                            "--b": "100%",
                                            color: "black",
                                            ...defaultUnderlineStyle,
                                        },
                                        _focus: {
                                            "--b": "100%",
                                            color: "black",
                                            ...defaultUnderlineStyle,
                                        },
                                        _active: {
                                            textDecoration: "none",
                                            color: "black",
                                        },
                                    }}
                                >
                                    {microcopies[ClickAndCollect.stores]}
                                </NextLink>
                                {isAuthenticated && (
                                    <NextLink
                                        href={getPagePathByLocale(customerServicePages, locale)}
                                        leftIcon={<ECCOIcon name="help" />}
                                        aria-label={microcopies[CommonCMS.help]}
                                        textDecoration="none"
                                        sx={{
                                            _hover: {
                                                "--b": "100%",
                                                color: "black",
                                                ...defaultUnderlineStyle,
                                            },
                                            _focus: {
                                                "--b": "100%",
                                                color: "black",
                                                ...defaultUnderlineStyle,
                                            },
                                            _active: {
                                                textDecoration: "none",
                                                color: "black",
                                            },
                                        }}
                                    >
                                        {microcopies[CommonCMS.help]}
                                    </NextLink>
                                )}
                            </Flex>
                            <MobileBottomContainer {...MobileNavigationContainerProps} />
                        </Flex>
                    </motion.div>
                    <Portal>
                        <Box
                            sx={{
                                position: "absolute",
                                backgroundColor: "rgba(0,0,0,0.6)",
                                opacity: isOpen ? 0.3 : 0,
                                visibility: isOpen ? "visible" : "hidden",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 3,
                                transition:
                                    "opacity .45s  cubic-bezier(0.85, 0, 0.15, 1), visibility .45s  cubic-bezier(0.85, 0, 0.15, 1)",
                            }}
                        ></Box>
                    </Portal>
                </>
            )}
        </>
    );
};
