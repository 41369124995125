import type { SystemStyleObject } from "@chakra-ui/theme-tools";
import { focusOutline } from "../../../css/02.focus/focus.styles";
import { defaultUnderlineStyle } from "../../../css/common";

const baseStyle: SystemStyleObject = {
    display: "inline-block",
    transitionProperty: "common",
    transitionDuration: "fast",
    transitionTimingFunction: "ease-out",
    cursor: "pointer",
    outline: "none",
    color: "inherit",
    textDecoration: "none",
    fontWeight: "bold",
    lineHeight: "base",

    _focusVisible: {
        ...focusOutline,
        zIndex: "1",
        position: "relative",
    },
    _hover: {
        ...defaultUnderlineStyle,
    },
    _active: {
        outline: "0",
        ...defaultUnderlineStyle,
    },
};

const variants: Record<string, SystemStyleObject> = {
    underline: {
        ...baseStyle,
        ...defaultUnderlineStyle,
        _hover: {
            color: "gray.500",
            textDecorationColor: "gray.500",
        },
    },
    gray: {
        ...baseStyle,
        ...defaultUnderlineStyle,
        color: "gray.500",
        _hover: {
            color: "black",
        },
        _focusVisible: {
            ...focusOutline,
            outlineColor: "gray.500",
            zIndex: "1",
            position: "relative",
            textDecoration: "none",
        },
    },
};

export const linkStyles = {
    baseStyle,
    variants,
};
