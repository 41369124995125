export const styles = {
    container: {
        w: "100%",
    },
    userContainer: {
        mb: "0.5em",
        color: "gray.800",
    },
    logoutText: {
        user: {
            color: "black",
        },
    },
    buttons: {
        mr: "px",
        _hover: {
            textDecoration: "none",
        },
    },
};
