export const noHoverOnTouchDevice = "@media (hover: hover) and (pointer: fine)";

export const defaultUnderlineStyle = {
    textDecoration: "underline",
    textDecorationThickness: "1px",
    textUnderlineOffset: "4px",
};

export const animatedUnderline = {
    ...defaultUnderlineStyle,
    textDecorationColor: "black",
    transition: "text-decoration-color 300ms",
};
