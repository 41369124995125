import {
    blackFridayStyles,
    cyberMondayStyles,
    defaultBadgeStyles,
} from "@components/ui/product-badges/styles";
import { CommonCMS } from "@lib/constants/contentful";

export enum ProductBadgeVariant {
    DEFAULT = "default",
    BLACK_FRIDAY = "black_friday",
    CYBER_MONDAY = "cyber_monday",
}

export const badgesStyles = {
    [ProductBadgeVariant.DEFAULT]: defaultBadgeStyles,
    [ProductBadgeVariant.BLACK_FRIDAY]: blackFridayStyles,
    [ProductBadgeVariant.CYBER_MONDAY]: cyberMondayStyles,
};

export interface Badge {
    name: string;
    type: ProductBadgeVariant;
    testId: string;
    order?: number;
}

const mapBadgeType = (badge: string, microcopies: Record<string, string>): ProductBadgeVariant => {
    const badgeLower = badge.toLowerCase();
    if (badgeLower === microcopies[CommonCMS.salesDays.blackFriday]?.toLowerCase()) {
        return ProductBadgeVariant.BLACK_FRIDAY;
    }
    if (badgeLower === microcopies[CommonCMS.salesDays.cyberMonday]?.toLowerCase()) {
        return ProductBadgeVariant.CYBER_MONDAY;
    }
    return ProductBadgeVariant.DEFAULT;
};

export const formatBadges = (
    customBadges: string[],
    microcopies: Record<string, string>
): Badge[] => {
    if (!customBadges?.length) return [];

    const priorities = {
        [microcopies[CommonCMS.salesDays.blackFriday]?.toLowerCase()]: 1,
        [microcopies[CommonCMS.salesDays.cyberMonday]?.toLowerCase()]: 1,
    };

    const sortedBadges = [...customBadges].sort(
        (a, b) =>
            (priorities[a.toLowerCase()] || Infinity) - (priorities[b.toLowerCase()] || Infinity)
    );

    return sortedBadges.map((badge) => ({
        name: badge,
        type: mapBadgeType(badge, microcopies),
        testId: mapBadgeType(badge, microcopies),
    }));
};
