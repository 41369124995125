import { CommonCMS } from "@lib/constants/contentful";
import type {
    MicrocopySetKey,
    MicrocopyGetOptions,
} from "@lib/contentful/microcopy/MicrocopyContext";

export const getAllowedQuestionLabels = (
    getMicrocopy: (
        microcopySetKey: MicrocopySetKey,
        microcopyKey: string,
        options?: MicrocopyGetOptions
    ) => string | null
) => {
    return [
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.generalInfo),
            value: "General Information",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.orderStatus),
            value: "Order Status",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.cancelOrder),
            value: "Cancel/Change Order",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.myAccount),
            value: "My Account",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.laceRequest),
            value: "Lace/Insole Request",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.shipmentIssues),
            value: "Shipment Issues",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.productInfo),
            value: "Product Information",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.promo),
            value: "Promotion/Discount Information",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.return),
            value: "Return",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.exchange),
            value: "Exchange",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.shoeCare),
            value: "Shoe Care",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.warranty),
            value: "Warranty Claim",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.website),
            value: "Website Issue",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.refunds),
            value: "Refunds",
        },
        {
            label: getMicrocopy(CommonCMS.global, CommonCMS.contactForm.options.other),
            value: "Other",
        },
    ];
};
