import type { Variant } from "@components/commerce/product/product-tile/ProductTile";
import { ProductTile } from "@components/commerce/product/product-tile/ProductTile";
import type { Document } from "@contentful/rich-text-types";
import { AnalyticsClient } from "@lib/analytics/AnalyticsClient";
import type { AlgoliaItem } from "@lib/analytics/analyticsTypes";
import { CommonCMS } from "@lib/constants/contentful";
import { pluralizeHelper, useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import {
    addToMyShoppingList,
    createMyShoppingList,
    removeFromMyShoppingList,
    showErrorToaster,
    signInAndGetSession,
} from "@lib/utils/shoppingList-utility/shoppingListUtility";
import { useErrorToast } from "@lib/utils/store-utility/storeUtility";
import {
    useAddProductAndRemoveOldestFromWishlist,
    useAddProductToWishlist,
    useCreateMyWishlist,
    useGetMyActiveShoppingList,
    useLock,
    useRemoveProductFromWishlist,
} from "@store/hooks";
import { Box } from "@ui/components/layout/box/Box";
import { Carousel } from "@ui/components/navigation/carousel/Carousel";
import { useSession } from "next-auth/react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import { useStore, useStoreApi, type StoreState } from "store";

const ProductCarouselSkeleton = dynamic(
    () => import("@components/commerce/product/product-carousel/ProductCarouselSkeleton")
);

const AddedToWishlistModal = dynamic(
    () => import("@components/ui/modals/add-to-wishlist-modal/AddedToWishlistModal")
);

type ProductCarouselProps = {
    slideData: AlgoliaItem[];
    assistiveMessage: string;
    headline: string;
    slidesPerView?: number[];
    carouselType?: string;
    authorizationInvitation?: Document;
    extraLink?: ReactNode;
    id?: string;
    isLoading?: boolean;
};

export const ProductCarousel: FC<ProductCarouselProps> = (props) => {
    const {
        slideData,
        slidesPerView = [1.3, 2.5, 3.5],
        headline,
        assistiveMessage,
        carouselType,
        authorizationInvitation,
        extraLink = null,
        id,
        isLoading,
    } = props;

    const { data: session } = useSession();
    const api = useStoreApi();
    const { locale } = useRouter();
    const errorToaster = useErrorToast();
    const { setWishlistIsExecuted, setAlgoliaQueryID } = useStore<StoreState>();
    const wishlist = api.getState().data.me.wishlistInfo.wishlist;
    const lock = useLock();
    const { get: getMicrocopy } = useMicrocopy();

    const [isOpenAddedToWishlistModal, setIsOpenAddedToWishlistModal] = useState<boolean>(false);
    const [selectedHitId, setSelectedHitId] = useState<string>();

    const executeCreateMyWishlist = useCreateMyWishlist();
    const executeGetMyActiveWishlist = useGetMyActiveShoppingList();
    const executeAddProductToWishlist = useAddProductToWishlist();
    const executeAddProductAndRemoveOldestFromWishlist = useAddProductAndRemoveOldestFromWishlist();
    const executeRemoveProductFromWishlist = useRemoveProductFromWishlist();

    const handleAddProductHitToWishlist = lock(async (hit) => {
        setWishlistIsExecuted(false);
        setIsOpenAddedToWishlistModal(true);
        await signInAndGetSession(session, getMicrocopy, errorToaster);

        await executeGetMyActiveWishlist({});
        if (!api.getState().data.me.wishlistInfo.wishlist) {
            await createMyShoppingList(locale, getMicrocopy, executeCreateMyWishlist, errorToaster);
        }

        try {
            await addProductHitToWishlist(hit);
        } catch (error) {
            showErrorToaster(getMicrocopy, errorToaster);
        }
        setWishlistIsExecuted(true);
    });

    const addProductHitToWishlist = async (hit) => {
        const foundItem = wishlist?.lineItems.find(
            (x) =>
                x.variant?.sku === hit.objectID ||
                hit.variants?.some((v: Variant) => v.sku === x.variant?.sku)
        );
        const sku = foundItem?.variant?.sku || hit.objectID;

        setSelectedHitId(sku);

        if (foundItem) {
            await Promise.all([
                removeFromMyShoppingList(
                    locale,
                    foundItem,
                    wishlist,
                    executeRemoveProductFromWishlist
                ),
                AnalyticsClient.removeFromWishList(hit),
            ]);
        } else {
            await Promise.all([
                AnalyticsClient.addToWishList(hit),
                addToMyShoppingList(
                    locale,
                    wishlist,
                    sku,
                    executeAddProductToWishlist,
                    executeAddProductAndRemoveOldestFromWishlist
                ),
            ]);
        }
    };

    const [intersectItems, setIntersectItems] = useState(new Set());

    useEffect(() => {
        let timeoutId;
        if (intersectItems.size) {
            timeoutId = setTimeout(() => {
                AnalyticsClient.viewItemsList(
                    (slideData as AlgoliaItem[]).filter((item) =>
                        intersectItems.has(item.objectID)
                    ),
                    { listName: carouselType }
                );
                setIntersectItems(new Set());
            }, 2000);
        }
        return () => clearTimeout(timeoutId);
    }, [intersectItems]);

    if (isLoading) {
        return <ProductCarouselSkeleton />;
    } else if (!slideData?.length) {
        return null;
    }

    return (
        <Box
            w="full"
            mt="0.2"
            data-testid="product-carousel"
            data-contentful-entry-id={id}
            data-contentful-field-id={"moduleCustomProductCarousel"}
        >
            <Carousel
                title={headline}
                titleAriaLabel={assistiveMessage}
                showNavigation={false}
                slidesPerView={slidesPerView}
                gap={1}
                px={[0, 0, 0, 0]}
                py={[3, 3, 3, 3]}
                extraNavigationButtons={extraLink}
                h={["auto", "100vw", "66vw", "48vw", "45vw"]}
            >
                {slideData?.map((slide: any, index: number) => {
                    if (!slide) {
                        return null;
                    }
                    const hasStockCount = (slide.productColors || []).filter(
                        (color) => color.hasStock === true
                    ).length;

                    const colorMicrocopy = `${hasStockCount + 1} ${getMicrocopy(
                        CommonCMS.global,
                        CommonCMS.colour,
                        {
                            pluralize: pluralizeHelper(hasStockCount + 1),
                        }
                    )}`;

                    const foundItem = wishlist?.lineItems?.find(
                        (x) =>
                            x.variant?.sku === slide.objectID ||
                            slide.variants?.some((v: Variant) => v.sku === x.variant?.sku)
                    );

                    return (
                        <Box
                            w={[
                                "calc(100vw/1.3)",
                                "calc(100vw/2.5)",
                                "calc(100vw/2.5)",
                                "calc(100vw/3.5)",
                            ]}
                            key={slide.objectID}
                        >
                            <ProductTile
                                key={slide.objectID}
                                productName={slide.productName}
                                variants={slide.variants}
                                articleNumber={slide.articleNumber}
                                colorCode={slide.colorCode}
                                image={slide.image}
                                imageLabel={slide.imageLabel}
                                altTagPattern={`${slide.pageTitle ?? slide.productName} - ${slide.dominantColor}`}
                                objectID={slide.objectID}
                                isAddingToWishlist={Boolean(foundItem)}
                                onAddToWishlist={() => handleAddProductHitToWishlist(slide, index)}
                                priceWithDiscount={slide.priceWithDiscount}
                                priceWithoutDiscount={slide.priceWithoutDiscount}
                                discountPercentage={slide.discountPercentage}
                                imageBackgroundColor={slide.imageBackgroundColor}
                                productColors={slide.productColors}
                                colorLabel={colorMicrocopy}
                                locale={locale}
                                backgroundColor={slide.backgroundColor}
                                handleTileClick={() => {
                                    setAlgoliaQueryID(null);
                                    AnalyticsClient.selectItem(slide, {
                                        position: slide.index,
                                        listName: carouselType,
                                    });
                                }}
                                customBadges={slide.badges}
                                descriptiveHeading={slide.descriptiveHeading}
                                w="full"
                                onInViewPort={(inView, item) => {
                                    setIntersectItems((items) => {
                                        if (inView) {
                                            items.add(item);
                                        } else {
                                            items.delete(item);
                                        }
                                        return new Set(items);
                                    });
                                }}
                                hasStock={slide.hasStock}
                            />
                        </Box>
                    );
                })}
            </Carousel>
            {isOpenAddedToWishlistModal && (
                <AddedToWishlistModal
                    isOpen={isOpenAddedToWishlistModal}
                    onClose={() => setIsOpenAddedToWishlistModal(false)}
                    selectedProductSku={selectedHitId}
                    authorizationInvitation={authorizationInvitation}
                />
            )}
        </Box>
    );
};
