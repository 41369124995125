import { isEmpty } from "lodash-es";
import { forwardRef, useEffect, useRef } from "react";
import type { ILink, IPagePath } from "@contentful-api/types/contentful";
import { NextLink, type NextLinkProps } from "@ui/components/navigation/link/NextLink";
import { hasWindow } from "@lib/utils/hasWindow";

interface IInternalLinkProps extends Partial<NextLinkProps> {
    link: ILink | IPagePath;
}

export const InternalLink = forwardRef<HTMLAnchorElement, IInternalLinkProps>(
    ({ link, children, ...rest }, ref) => {
        const _link = link as ILink;
        const fields = _link?.fields;
        const internalLinkOnNewTab = fields?.internalLinkOnNewTab;
        const href = fields?.internalLink?.fields?.completeSlug || "";
        const label = fields?.label || "";
        const accordionRef = useRef<HTMLElement>(null);
        // We double check if the deepLink is not empty and the href is empty, otherwise will have prevalence the href
        const isDeepLink = !isEmpty(fields?.deepLink) && isEmpty(href);

        const scrollToElement = (element) => {
            const dimensions = element?.getBoundingClientRect();
            const offset = 120;
            const scrollTop = document.documentElement.scrollTop;

            window.scrollTo({
                top: dimensions?.top + scrollTop - offset,
                behavior: "smooth",
            });
        };

        const handleDeepLink = (e) => {
            e.preventDefault();
            if (isDeepLink) {
                const element = document?.querySelector(`#${fields.deepLink}`);
                const isInAccordion = element?.closest(".chakra-accordion__item");

                if (isInAccordion) {
                    const accordionBtn = isInAccordion.querySelector("button");
                    const isExpanded = accordionBtn.getAttribute("aria-expanded");

                    if (isExpanded === "false") {
                        accordionBtn.click();
                        setTimeout(() => {
                            scrollToElement(element);
                        }, 100);
                        return;
                    }
                    setTimeout(() => {
                        scrollToElement(element);
                    }, 100);
                    return;
                }
                scrollToElement(element);
            }
        };

        useEffect(() => {
            if (hasWindow) {
                const hash = window.location.hash;
                if (hash && isDeepLink) {
                    const element = document?.querySelector(`${hash}`);
                    // We need to do this DOM Scripting to read if there is any deep linking in accordion on load. If any, open them and scroll to the element.
                    // Accordions are handle via JS, so we need to wait for them to be loaded on the browser.
                    const isInAccordion = element?.closest(
                        ".chakra-accordion__item"
                    ) as HTMLElement;

                    accordionRef.current = isInAccordion;
                    if (isInAccordion) {
                        setTimeout(() => {
                            const accordionBtn = accordionRef.current.querySelector("button");
                            accordionBtn.setAttribute("aria-expanded", "true");
                            const collapsedElement =
                                isInAccordion?.querySelector<HTMLElement>(".chakra-collapse");
                            // Remove styles of the collapsed element to make it visible, click() method doesn't work in this approach
                            collapsedElement.setAttribute(
                                "style",
                                "opacity: 1; height: auto; display: block"
                            );
                            const { top } = element.getClientRects()[0];
                            window.scrollBy({
                                top: top - 120,
                                behavior: "smooth",
                            });
                        }, 100);
                    }
                }
            }

            return () => {};
        }, []);

        const target = internalLinkOnNewTab ? "_blank" : "_self";
        return isDeepLink ? (
            <NextLink
                data-testid={fields?.internalLink?.fields?.pageTypeId}
                href={`#${fields.deepLink}`}
                {...rest}
                ref={ref}
                onClick={(e) => handleDeepLink(e)}
            >
                {isEmpty(children) ? label : children}
            </NextLink>
        ) : (
            <NextLink
                data-testid={fields?.internalLink?.fields?.pageTypeId}
                href={href}
                target={target}
                ref={ref}
                {...rest}
            >
                {isEmpty(children) ? label : children}
            </NextLink>
        );
    }
);

InternalLink.displayName = "InternalLink";
