import type { ReactElement, ReactNode } from "react";
import type { Options as RenderOptions } from "@contentful/rich-text-react-renderer";
import type { Block, Inline, Node } from "@contentful/rich-text-types";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { H } from "@ui/components/content/heading/A11yHeading";
import { Text } from "ui/src/components/content/text/Text";
import { Flex } from "ui/src/components/layout/flex/Flex";
import {
    List,
    ListItem,
    OrderedList,
    UnorderedList,
} from "ui/src/components/data-display/list/List";
import { Divider } from "ui/src/components/data-display/divider/Divider";
import { LinkBox } from "ui/src/components/navigation/link/LinkOverlay";
import { Box } from "ui/src/components/layout/box/Box";
import { NextImage } from "ui/src/components/media-and-icons/image/NextImage";
import type { Replacements } from "@lib/utils/interpolateText";
import { interpolateText } from "@lib/utils/interpolateText";
import type { ILink } from "@contentful-api/types/contentful";
import { isEmpty } from "lodash-es";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { IconButton } from "@ui/components/forms/icon-button/IconButton";
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    AccordionIcon,
} from "@ui/components/disclosure/accordion/Accordion";
import { renderRichTextToComponent } from "./renderRichTextToComponent";
import { Link as ContentfulLink } from "@components/cms/link/Link";
import { Link } from "@ui/components/navigation/link/Link";
import AccessibilityFeedback from "@components/cms/accessibility/AccessibilityFeedback";
import AccessibilityToggle from "@components/cms/accessibility/AccessibilityToggle";
export interface Options {
    replacements?: Replacements;
}

// TODO: add table components, ASSET_HYPERLINK, EMBEDDED_ENTRY, EMBEDDED_ASSET
export function getOptions(options?: Options): RenderOptions {
    return {
        renderMark: {
            [MARKS.BOLD]: (text: ReactNode): JSX.Element => (
                <Text as="span" fontWeight="bold" fontSize={"inherit"}>
                    {text}
                </Text>
            ),
            [MARKS.UNDERLINE]: (text: ReactNode): JSX.Element => (
                <Text as="span" textDecoration="underline" fontSize={"inherit"}>
                    {text}
                </Text>
            ),
            [MARKS.ITALIC]: (text: ReactNode): JSX.Element => (
                <Text as="span" fontStyle="italic" fontSize={"inherit"}>
                    {text}
                </Text>
            ),
            [MARKS.CODE]: (text: ReactElement): JSX.Element => {
                return (
                    <Text
                        as="span"
                        visibility={"hidden"}
                        // When mark <code> is used within a Paragraph is adding an extra space. So we are trimming it to map the correct id.
                        id={text.props.children.trim()}
                        fontSize={"inherit"}
                    >
                        {text}
                    </Text>
                );
            },
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (_node: Inline | Block, children: ReactNode): JSX.Element => (
                <Text
                    color={"inherit"}
                    mb="1em"
                    fontSize={{ base: "mobileBodyTextNormal", lg: "desktopBodyTextNormal" }}
                    whiteSpace={"pre-wrap"}
                    wordBreak={"break-word"}
                >
                    {children}
                </Text>
            ),
            [BLOCKS.HEADING_1]: (_node: Inline | Block, children: ReactNode): JSX.Element => (
                <H mb={[6, 6, 6, 8]} as="h1" fontSize={["mobileHeading1", "desktopHeading1"]}>
                    {children}
                </H>
            ),
            [BLOCKS.HEADING_2]: (_node: Inline | Block, children: ReactNode): JSX.Element => (
                <H mb={6} as="h2" fontSize={["mobileHeading2", "desktopHeading2"]}>
                    {children}
                </H>
            ),
            [BLOCKS.HEADING_3]: (_node: Inline | Block, children: ReactNode): JSX.Element => (
                <H mb={6} as="h3" fontSize={["mobileHeading3", "desktopHeading3"]}>
                    {children}
                </H>
            ),
            [BLOCKS.HEADING_4]: (_node: Inline | Block, children: ReactNode): JSX.Element => (
                <H mb={6} as="h4" fontSize={["mobileHeading4", "desktopHeading4"]}>
                    {children}
                </H>
            ),
            [BLOCKS.HEADING_5]: (_node: Inline | Block, children: ReactNode): JSX.Element => (
                <H mb={6} as="h5" fontSize={["mobileHeading5", "desktopHeading5"]}>
                    {children}
                </H>
            ),
            [BLOCKS.HEADING_6]: (_node: Inline | Block, children: ReactNode): JSX.Element => (
                <H mb={6} as="h6" fontSize={["mobileBodyTextLarge", "desktopBodyTextLarge"]}>
                    {children}
                </H>
            ),
            [BLOCKS.HR]: (_node: Inline | Block, _children) => <Divider my={2} />,
            [BLOCKS.UL_LIST]: (_node: Inline | Block, children: ReactNode): JSX.Element => (
                <List
                    color="black"
                    mb="1em"
                    fontSize={[
                        "mobileBodyTextNormal",
                        "mobileBodyTextNormal",
                        "mobileBodyTextNormal",
                        "desktopBodyTextNormal",
                    ]}
                >
                    <UnorderedList fontSize={"inherit"}>{children}</UnorderedList>
                </List>
            ),
            [BLOCKS.OL_LIST]: (_node: Inline | Block, children: ReactNode): JSX.Element => (
                <List
                    color="black"
                    mb="1em"
                    fontSize={[
                        "mobileBodyTextNormal",
                        "mobileBodyTextNormal",
                        "mobileBodyTextNormal",
                        "desktopBodyTextNormal",
                    ]}
                >
                    <OrderedList fontSize={"inherit"}>{children}</OrderedList>
                </List>
            ),
            [BLOCKS.LIST_ITEM]: (_node: Inline | Block, children: ReactNode): JSX.Element => (
                <ListItem>{children}</ListItem>
            ),
            [BLOCKS.QUOTE]: (_node: Inline | Block, children: ReactNode): JSX.Element => (
                // TODO: if need, should be changed by custom quote component
                <Box bg={"gray.100"} borderLeftWidth={4} borderLeftColor={"gray.400"} p={2}>
                    {children}
                </Box>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node: Inline | Block): JSX.Element => {
                const data = node?.data?.target?.fields;
                // Wrapping Image into Flex element prevent unexpected behavior like moving out of the wrapping box by image
                return (
                    <Flex
                        mb={[6, 6, 6, 8]}
                        maxW={`${data?.file?.details?.image?.width}px`}
                        height={`${data?.file?.details?.image?.height}px`}
                    >
                        <NextImage
                            src={data?.file?.url}
                            alt={data?.description}
                            quality={85}
                            objectFit={"cover"}
                            sizes={["50vw"]}
                        />
                    </Flex>
                );
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node: Inline | Block): JSX.Element => {
                const data = node?.data?.target;
                const accordionHeader = data?.fields?.header;
                const accordionItems = data?.fields?.accordionItems;
                return (
                    <Flex direction={"column"}>
                        {accordionHeader && (
                            <H size="h5" textAlign="start" mb={[4, 4, 6]}>
                                {accordionHeader}
                            </H>
                        )}
                        <Accordion w="full" allowMultiple>
                            {accordionItems?.map((item, index) => {
                                return (
                                    <AccordionItem
                                        key={index}
                                        sx={{
                                            backgroundColor: "transparent",
                                            borderRadius: 0,
                                            py: 4,
                                        }}
                                    >
                                        <AccordionButton
                                            sx={{
                                                pt: 0,
                                                px: 0,
                                                w: "100%",
                                                "&[aria-expanded='true']": { px: 0 },
                                                "&:hover": { bg: "transparent" },
                                            }}
                                            display="flex"
                                            justifyContent={"space-between"}
                                            gap={2}
                                        >
                                            <Box textAlign="left">{item.fields.heading}</Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pb={4} px={0}>
                                            {renderRichTextToComponent(item.fields.text)}
                                        </AccordionPanel>
                                    </AccordionItem>
                                );
                            })}
                        </Accordion>
                    </Flex>
                );
            },
            [INLINES.ENTRY_HYPERLINK]: (node: Node, children: ReactNode): JSX.Element => {
                const link: ILink = node?.data?.target;
                const icon = link?.fields?.icon?.toLocaleLowerCase();

                if (node?.data?.target.fields.modal === "accessibilityFeedback") {
                    return <AccessibilityFeedback />;
                }

                if (node?.data?.target.fields.modal === "accessibilityToggle") {
                    return <AccessibilityToggle />;
                }

                const Icon = !isEmpty(icon) ? (
                    <IconButton
                        aria-label={link?.fields?.label}
                        icon={<ECCOIcon name={icon} />}
                        bg="gray.50"
                        color="black"
                        size="sm"
                        fontSize="inherit"
                    />
                ) : null;
                return (
                    <ContentfulLink
                        link={link}
                        color="inherit"
                        sx={{ whiteSpace: ["normal", "nowrap"] }}
                        variant="underline"
                        fontSize="inherit"
                    >
                        {children}
                        {Icon}
                    </ContentfulLink>
                );
            },
            [INLINES.EMBEDDED_ENTRY]: (node: Node): JSX.Element => {
                const image = node?.data;

                return (
                    <NextImage
                        src={image?.url}
                        width={image?.width}
                        height={image?.height}
                        alt={image?.description}
                        quality={85}
                        sizes={["50vw"]}
                    />
                );
            },
            [INLINES.HYPERLINK]: (node: Node, children: ReactNode): JSX.Element => {
                return (
                    <LinkBox as="span">
                        <Link color="black" href={node?.data?.uri?.trim()} fontSize="inherit">
                            {children}
                        </Link>
                    </LinkBox>
                );
            },
        },
        renderText: (text: string): JSX.Element => (
            <>{options?.replacements ? interpolateText(text, options?.replacements) : text}</>
        ),
    };
}
