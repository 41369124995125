import { getSiteSettings } from "@config/site/site-api-settings";
import { getLanguageFromLocale } from "@config/site/site-config";
import Script from "next/script";

interface CookieConsentScriptProps {
    locale: string;
}

export const CookieConsentScript = ({ locale }: CookieConsentScriptProps) => {
    const marketLanguage = getLanguageFromLocale(locale).toUpperCase();
    const supportGPC = getSiteSettings.supportsGlobalPrivacyControl;
    const isProduction = process.env.NODE_ENV === "production";

    return (
        <>
            {supportGPC && (
                <Script id="cookieInformationCustomConfig" strategy="afterInteractive">{`
                 window.cookieInformationCustomConfig = {
                     GPP: {
                         applicableSections: [
                             { id: 7, name: 'usnatv1' },
                             { id: 8, name: 'uscav1' },
                             { id: 10, name: 'uscov1' },
                             { id: 12, name: 'usctv1' }
                         ]
                     }
                 };
             `}</Script>
            )}
            {/* Cookie Consent script doesn't load locally */}
            {isProduction && (
                <Script
                    strategy="afterInteractive"
                    id="CookieConsent"
                    src="https://policy.app.cookieinformation.com/uc.js"
                    data-culture={marketLanguage}
                    type="text/javascript"
                    data-gcm-version="2.0"
                    {...(supportGPC && { "data-gpp-enabled": "true" })}
                />
            )}
        </>
    );
};
